import React from 'react';
import "./Home.css";
import Product from './Product';
import BannerAde from './BannerAde';
import ImageSlider from './ImageSlider';
import { SliderData } from './SliderData';


function Home() {
    return (
        <div className='home'>
           <div className="home__container">

             <div className="sliders">
           <ImageSlider slides={SliderData} />
           </div>
           
           <div className="rows">

           <div className="home__row">
           <div className="details">
             <p>You are on amazon.com. You can also shop on Amazon UK for millions of products with fast local delivery.<a target="_blank" rel="noreferrer" href="https://www.amazon.co.uk/?ref=aisgw_intl_stripe_uk"> Click here to go to amazon.co.uk</a></p>
           </div>
             </div> 


           <div className="home__row">
             <Product 
             title='Playstation DualSense Wireless Controller'
             price={49.99}
            rating={4}
            image="https://m.media-amazon.com/images/I/61o7ai+YDoL._AC_UY218_.jpg"/>

               <Product 
             title="Marvel's Spider-Man: Miles Morales Ultimate Edition - Playstation 5"
             price={55.99}
            rating={5}
            image="https://m.media-amazon.com/images/I/71Kwo5V9QOL._AC_UY218_.jpg"/>
           </div>
           
           <div className="home__row">
           <Product 
             title='ASUS TUF VR Ready Gaming Laptop, 15.6" IPS FHD, AMD Ryzen 7-4800H, GeForce RTX 2060, 32GB RAM, 1TB SSD + 1TB HDD'
             price={1750.99}
            rating={4}
            image="https://m.media-amazon.com/images/I/71irrrQ8I-L._AC_UY218_.jpg"/>

             <Product 
             title='New Dell Alienware m17 R3 17.3" FHD 144Hz Gaming Laptop, 10th Gen Intel Core i7-10750H, GeForce RTX 2060 6GB, 16GB RAM, 512GB SSD, Win 10'
             price={1799.00}
            rating={3}
            image="https://m.media-amazon.com/images/I/81CN12QkUWL._AC_UY218_.jpg"/>

             <Product 
             title='Laptop Cooling Pad, Laptop Cooler with 6 Quiet Led Fans for 15.6-17 Inch Laptop Cooling Fan Stand, Portable Ultra Slim USB Powered Gaming Laptop Cooling Pad'
             price={28.89}
            rating={4}
            image="https://m.media-amazon.com/images/I/71cCNyMZhZL._AC_UY218_.jpg"/>
           </div>


           <div className="home__row">
            <BannerAde
             image="https://images-eu.ssl-images-amazon.com/images/G/02/SBP/2021/IWD/Gateway/SBE_IWD21_Homepage_Hero_Editorial_UK_Day6_1X._CB658011966_.jpg"/>
           </div>


           <div className="home__row">
           <Product 
             title='Amazon Basics Vinyl Coated Hand Weight Dumbbell Pair, Set of 2'
             price={17.50}
            rating={3}
            image="https://m.media-amazon.com/images/I/71aEZlrJCpL._AC_UL320_.jpg"/>

             <Product 
             title='Curad Assorted Bandages Variety Pack 300 Pieces, Including Antibacterial, Heavy Duty, Fabric, and Waterproof Bandages'
             price={21.55}
            rating={4}
            image="https://m.media-amazon.com/images/I/91nMKn2QZxL._AC_UL320_.jpg"/>

             <Product 
             title='Wave Runner Soft Foam Water Skipping Ball | 2-Pack Bundle | Speed Duo Set Includes Two Water Bouncing Balls Mega Ball'
             price={21.99}
            rating={4}
            image="https://m.media-amazon.com/images/I/51H9iajfcqL._AC_UL320_.jpg"/>
           </div>



           <div className="home__row">
           <Product 
             title='SAMSUNG 85-inch Class Crystal UHD TU-8000 Series - 4K UHD HDR Smart TV'
             price={4350.99}
            rating={5}
            image="https://m.media-amazon.com/images/I/91FcuuZwcrL._AC_UY218_.jpg"/>
           </div>

           </div>
           </div>
           </div>
    )
};

export default Home
