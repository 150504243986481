import React, {useEffect} from "react";
import './App.css';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';
import Checkout from './Checkout';
import Login from './Login';
import ProductDetails from './Details';
import Payment from './Payment';
import Orders from './Orders';
import ScrollUp from './scroll_up';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {auth} from "./firebase";
import { useStateValue } from './StateProvider';



function App() {
const [{}, dispatch] = useStateValue();
  useEffect(()=>{
auth.onAuthStateChanged(authUser => {
  console.log('THE USER IS >>> ', authUser);

  if (authUser){
    //the user just logged in/ the user was logged in
  dispatch({
    type: 'SET_USER',
    user: authUser
  })

  } else {
    // the user is logged in
    dispatch({
      type:'SET_USER',
      user: null
    })
  }
})
  },[])
  return (
    <Router>
    <div className="app">
   
      <Switch>

      <Route path="/details">
      <Header/>
      <ProductDetails/>
      </Route>

      <Route path="/orders">
        <Orders/>
      </Route>


      <Route path="/login">
        <Login/>
      </Route>


      <Route path="/checkout">
      <Header/>
      <Checkout/>
      <ScrollUp/>
      <Footer/>
      </Route>


      <Route path="/Payment">
      <Header/>
      <Payment/>
      <ScrollUp/>
      <Footer/>
      </Route>


      <Route path="/">
      <Header/>
      <Home/>
      <ScrollUp/>
      <Footer/>
      </Route>

      </Switch>
  
    </div>
    </Router>
  );
}

export default App;
