export const SliderData = [
  {
    image:'https://images-eu.ssl-images-amazon.com/images/G/02/digital/video/gateway/placement/launch/ElInternadoS1/ELINT_S1_GWBleedingHero_FT_COVIDUPDATE_XSite_1500X600_PV_en-GB._CB659172682_.jpg'
  },
  {
    image:'https://images-na.ssl-images-amazon.com/images/G/01/AmazonExports/Fuji/2020/May/Hero/Fuji_TallHero_Beauty_v2_en_US_1x._CB429089975_.jpg'
  },
  {
    image:'https://images-na.ssl-images-amazon.com/images/G/01/AmazonExports/Fuji/2020/May/Hero/Fuji_TallHero_Computers_1x._CB432469755_.jpg'
  },
  {
    image:'https://images-eu.ssl-images-amazon.com/images/G/02/AmazonMusic/2021/Marketing/EvergreenQ1/Gateway/UK-EN_AMF_EvergreenQ1_DMUX-3799_JZ_OnS_GW_Hero_D_1500x600_1X_CV4._CB661903594_.jpg'
  },
  {
    image:'https://images-eu.ssl-images-amazon.com/images/G/02/AMAZON-FASHION/2021/FASHION/Amazon-Essentials/GW_HERO_DESKTOP_1500x600._CB662589438_.jpg'
  },
];
