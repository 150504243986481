import React from 'react'
import "./BannerAde.css";

function BannerAde({image}) {
    
    return (
        <div className="banner">
            <img src={image} alt="ads"/>
        </div>
    )
}

export default BannerAde