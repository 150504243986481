import React from 'react';
import './Header.css'
import SearchIcon from "@material-ui/icons/Search"
import { useStateValue } from './StateProvider';
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import { Link } from "react-router-dom";
import {auth} from "./firebase"; 

function Header() {

    const[{basket, user}, dispatch]= useStateValue();

    const handleAuthentication = () => {
      if(user){
        auth.signOut();
      }
    }
    const clearAll = () => {
      dispatch({
          type: 'EMPTY_DETAILS'
      })
  }

    return (
        <div className='header'>
            <Link to="/">
            <img onClick={clearAll}
            className="header__logo" 
           src="amazon_white_logo.png" alt="amazon_logo"
             />
            </Link>
           
          
          
           <div
           className="header__search">
               <input
               className="header__searchInput" type="text"/>
               <SearchIcon className="header__searchIcon"></SearchIcon>
               </div>

               <div className="header__nav">
                   <Link to={!user && '/login'}>
                   <div onClick={handleAuthentication} className="header__option"> 
                      <span className='header__optionLineOne'>Hello {!user ? 'Guest' : user?.email}</span>
                     <span
                      className='header_optionLineTwo'>{user ?
                      'Sign Out' : 'Sign In'}</span>
                   </div>
                   </Link>

                    <div className="return">
                   <div className="header__option">
                   <span className='header__optionLineOne'>Returns &</span>
                      <span
                      className='header_optionLineTwo'>Orders</span>
                   </div>
                   </div>

                  <div className="hide">
                   <div className="header__option"> 
                   <span className='header__optionLineOne'>Your</span>
                      <span
                      className='header_optionLineTwo'>Prime</span>
                   </div>
                   </div>

                 <Link to="/checkout">
                 <div className="header__optionBasket">
                   <ShoppingBasketIcon />
                  <span className="header_optionLineTwo
                  header__basketCount">{basket?.length}</span>
                 </div>
                 </Link>
                 </div>  
                 </div>
    )
}

export default Header
