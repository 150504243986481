import React from 'react'

function scroll_up() {
    
function scrollUp(){

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
   
}
    return (
        <div>
            <button className="btn__bt" onClick={scrollUp}>Back to top</button>
        </div>
        
    )
}

export default scroll_up
