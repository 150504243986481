import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";

const Footer = () => {
return (
	<footer>
	<Box>

	<Container>
		<Row>
		<Column>
			<Heading>Let Us Help You</Heading>
			<FooterLink href="#">Amazon and COVID-19</FooterLink>
			<FooterLink href="#">Your Account</FooterLink>
			<FooterLink href="#">Your Orders</FooterLink>
			<FooterLink href="#">Shipping Rates & Policies</FooterLink>
			<FooterLink href="#">Returns & Replacements</FooterLink>
			<FooterLink href="#">Manage Your Content and Devices</FooterLink>
			<FooterLink href="#">Amazon Assistant</FooterLink>
			<FooterLink href="#">Help</FooterLink>
		</Column>

		<Column>
			<Heading>Get to Know Us</Heading>
			<FooterLink href="#">Careers</FooterLink>
			<FooterLink href="#">Blog</FooterLink>
			<FooterLink href="#">About Amazon</FooterLink>
			<FooterLink href="#">Investor Relations</FooterLink>
			<FooterLink href="#">Amazon Devices</FooterLink>
			<FooterLink href="#">Amazon Tours</FooterLink>
		</Column>
		<Column>

			<Heading>Make Money with Us</Heading>
			<FooterLink href="#">Sell products on Amazon</FooterLink>
			<FooterLink href="#">Sell on Amazon Business</FooterLink>
			<FooterLink href="#">Sell apps on Amazon</FooterLink>
			<FooterLink href="#">Become an Affiliate</FooterLink>
			<FooterLink href="#">Advertise Your Products</FooterLink>
			<FooterLink href="#">Self-Publish with Us</FooterLink>
			<FooterLink href="#">Host an Amazon Hub</FooterLink>
			<FooterLink href="#">›See More Make Money with Us</FooterLink>
		</Column>
		<Column>
			<Heading>Amazon Payment Products</Heading>
			<FooterLink href="#">Amazon Business Card</FooterLink>
			<FooterLink href="#">Shop with Points</FooterLink>
			<FooterLink href="#">Reload Your Balance</FooterLink>
			<FooterLink href="#">Amazon Currency Converter</FooterLink>
		</Column>
	
		</Row>
	</Container>
	</Box>
	</footer>
);
};
export default Footer;
