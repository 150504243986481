import React, {createContext, useContext, useReducer} from "react";
//prepares data layer//
export const StateContext= createContext();
//data layer//
export const StateProvider = ({reducer, initialState, children}) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);
//for pulling info from data layer//
export const useStateValue= ()=> useContext(StateContext);