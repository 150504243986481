import React from 'react'
import './ProductDetails.css'
/**import "./animation.js";**/
import { useStateValue } from './StateProvider';

function ProductDetails({id, image, title, price, rating}) {



    const[{prod}, dispatch]= useStateValue();

   const addToBasket = ()=> {
      dispatch({
          type: 'ADD_TO_BASKET',
          item:{
              id: id,
              title: title,
              image: image,
              price: price,
              rating: rating,
          },
      });
  };


    return (
        <div class="container">
        <div class="card">
            <div class="sneaker">
                <div class="circle"></div>
                <img src={image} alt="product"/>
            </div>
            <div class="info">
                
                <h3>{title}</h3>
                <div className="product__rating">
                        {Array(rating).fill().map((_, i)=>(
                            <p>⭐</p> 
                        ))}
                    </div>
                    <div class="price">
                <h4>{price}</h4>
                </div>
                <div class="purchase">
                <button onClick={addToBasket}>Add to Basket</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ProductDetails
