// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";
import "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBk0zbvlz-upY02VUibc_93bRdc5-RY6gk",
    authDomain: "clone-dc996.firebaseapp.com",
    projectId: "clone-dc996",
    storageBucket: "clone-dc996.appspot.com",
    messagingSenderId: "560934295472",
    appId: "1:560934295472:web:d04cc23c4a3df26c27a875",
    measurementId: "G-L1W4YE9N4W"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const db = firebaseApp.firestore();
  const auth = firebase.auth();

  export { db, auth};