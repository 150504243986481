import React from 'react'
import "./Checkout.css";
import { useStateValue } from './StateProvider';
import ProductDetails from './ProductDetails';
function Details() {
    const[{prod}, dispatch]= useStateValue();
    return (
        <div>
      
    {prod.map(item =>(
        <ProductDetails
        id={item.id}
        title={item.title}
        image={item.image}
        price={item.price}
        rating={item.rating}
        />  
    ))}
</div>
    )
}

export default Details
