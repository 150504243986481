import React from 'react'
import ReactDOM from "react-dom"
import CurrencyFormat from "react-currency-format";
import {db} from './firebase';
import { getBasketTotal } from './reducer';
import { useHistory } from 'react-router';
import { useStateValue } from "./StateProvider";
import './Payment.css'
import {Link} from "react-router-dom";
import CheckoutProduct from "./CheckoutProduct";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
function Payment() {
    const history = useHistory();
    const[{basket, user}, dispatch]= useStateValue();

    const onApprove = (data, actions) => {
        return actions.order.capture();
      }

     const createOrder = (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: "0.01",
              },
            },
          ],
        });
      }
      const clearAll = () => {
    dispatch({
        type: 'EMPTY_BASKET'
    })
    db
    .collection('users')
    .doc(user?.id)
    .collection('orders')
    

}

    return (
        <div className='payment'>
            <div className='payment__container'>
                <h1>
                    checkout(
                        <Link to="/checkout">{basket?.length}</Link>
                    )
                </h1>

                {/* payment section - delivery address*/}
                <div className='payment__section'>
                    <div className='payment__title'>
                    <h3>delivery address</h3>
                    </div>

                    <div className='payment__address'>
                    <p>{user?.email}</p>
                    <p>Auinata, aakaba</p>
                    <p>Auinata, Lebanon</p>
                    </div>

                </div>

                {/* payment section - Review items*/}
                <div className='payment__section'>
                    <div classname='payment__title'>
                        <h3>Review items and delivery</h3>
                    </div>
                    <div className='payment__items'>
                        {basket.map(item =>(
                            <CheckoutProduct
                            id={item.id}
                            title={item.title}
                            image={item.image}
                            price={item.price}
                            rating={item.rating}
                            />
                        ))}
                    </div>
                </div>

                {/* payment section - Payment method*/}
                <div className='payment__section'>
                    <div classname="payment__title">
                        <h3>Payment Method</h3>
                        <PayPalButton style={{position:"static"}}
                            createOrder={(data, actions) => createOrder(data, actions)}
                              onApprove={(data, actions) => onApprove(data, actions)}
                             
                            />
                             <Link to={'/orders'}>
                            <button className="up_btn" onClick={clearAll}>Cash on Delivery</button>
                            </Link>
                    </div>
                   <div className="payment__details">
                   <CurrencyFormat
              renderText={(value) => (
                  <>
                  <h3>
                      Subtotal ({basket.length} items):
                      <strong> {value}</strong>
                  </h3>
                  </>
              )} 
               decimalScale={2}
               value={getBasketTotal(basket)}
               displayType={"text"}
               thousandSeparator={true}
               prefix={"$"}
               /> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment
