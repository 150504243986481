import React from 'react'
import './Orders.css'
function Orders() {
    return (
        <div classname='orders'>
            <h1>Your orders</h1>
        </div>
    )
}

export default Orders
